<template>
	<v-dialog v-model="dialog" max-width="700">
		<v-card :loading="loading">
			<v-card-title class="headline">
				{{ encabezado }} Calendario
			</v-card-title>

			<v-card-text>
				<v-form ref="form" v-model="formValidity">
					<v-row class="justify-center">
						<v-col cols="12">
							<v-text-field label="Título" v-model="calendarEdit.title" :rules="[rules.required]" />
							<v-text-field type="date" label="Fecha Inicio" v-model="calendarEdit.date" />
							<v-text-field type="date" label="Fecha Fin" v-model="calendarEdit.dateEnd" />
							<v-text-field label="Subtitulo" v-model="calendarEdit.subtitle" />
							<v-text-field label="Coordinador(es)" v-model="calendarEdit.coordinator"  />
							<v-text-field label="Afiche" v-model="calendarEdit.poster"  />
							<div>Notas</div>
							<v-textarea
								v-model="calendarEdit.notes"
								:value="calendarEdit.notes"
								rows="3"
							/>
							<v-spacer class="mb-5" />
							<v-btn x-large @click.stop="storeCalendar()" :loading="saving" :disabled="!formValidity" rounded
								color="primary accent-3">
								GUARDAR CALENDARIO
							</v-btn>

						</v-col>
					</v-row>
				</v-form>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>

				<v-btn color="red darken-1" text @click="closeDialog()">
					CERRAR
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import axios from 'axios';
import store from '@/store'
const header = { "Token": store.state.userModule.token }
const configuration = { headers: header }

export default {
	name: 'DialogCalendar',
	props: {
		dialog: {
			default: false
		},
		mode: {
			default: 'New'
		},
		calendarEdit: {
			default: {}
		}
	},
	data: () => ({
		loading: false,
		saving: false,
		formValidity: false,
		rules: {
			required: value => !!value || 'Required.',
		},

	}),
	methods: {
		async storeCalendar() {
			let me = this
			me.saving = true
			if (me.mode == 'New') {

				await axios.post('calendar/add', me.calendarEdit, configuration)
					.then(function (response) {
						me.loading = false
						me.saving = false
						me.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'success',
							message: 'Calendario creado satisfactoriamente',
							timeout: 4500
						}, { root: true })
						me.$emit('getData')
						// me.closeDialog()
					})
					.catch(function (e) {
						me.loading = false
						me.saving = false
						me.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'red',
							message: 'Lo sentimos, su data no pudo ser procesada! Revise',
							timeout: 4500
						}, { root: true })
					});

			} else {

				this.saving = true
				await axios.put('calendar/update', {
					_id: me.calendarEdit._id,
					title: me.calendarEdit.title,
					subtitle: me.calendarEdit.subtitle,
					coordinator: me.calendarEdit.coordinator,
					date: me.calendarEdit.date,
					dateEnd: me.calendarEdit.dateEnd,
					poster: me.calendarEdit.poster,
					notes: me.calendarEdit.notes,
				}, configuration)
					.then(function (response) {
						me.$store.commit('SET_ALERT_MESSAGE', {
							show: true,
							color: 'success',
							message: 'Calendario actualizado satisfactoriamente',
							timeout: 4000
						}, { root: true })
						me.saving = false
					})
					.catch(function (e) {
						me.saving = false
						console.log(e.message)
					})

			}
		},
		closeDialog() {
			this.$refs.form.resetValidation()
			this.$emit('update:dialog', false)
		},
	},
	computed: {
		encabezado() {
			return (this.mode == "New") ? 'Nuevo' : 'Editar';
		}
	}

}
</script>

<style lang="scss" scoped></style>