<template>
	<Layout>
		<v-card class="d-sm-flex align-sm-center justify-sm-space-between mb-6 elevation-0">
			<v-card class="pa-2 text-left elevation-0 order-0 d-flex align-center">
				<h2> <v-icon large>mdi-calendar-clock</v-icon> CALENDARIO</h2>
				<v-btn fab dark color="primary" class="ml-3" @click="openDialog">
					<v-icon dark>
						mdi-plus
					</v-icon>
				</v-btn>
			</v-card>
			<v-card class="pa-2 order-2" flat width="400">
				<v-text-field class="text-right" v-model="search" append-icon="mdi-magnify" label="Search" single-line
					hide-details />
			</v-card>
		</v-card>
		<v-card>
			<v-card-text>
				<v-data-table :headers="headers" :items="calendars" :search="search" item-key="_id" class="elevation-1"
					:loading="loading" loading-text="Cargando... Espere" no-data-text="Sin registros para mostrar" :footer-props="{
						'items-per-page-options': [10, 15, 20, 50]
					}" :items-per-page="20">
					<template v-slot:item.evento="{ item }">
						{{ item.title }}
					</template>

					<template v-slot:item.date="{ item }">
						{{ dateFormat(item.date) }}
					</template>
					<template v-slot:item.dateEnd="{ item }">
						{{ dateFormat(item.dateEnd) }}
					</template>

					<!-- columna accion -->
					<template v-slot:item.action="{ item }">
						<v-icon class="botones_list" color="primary" @click="editCalendar(item)">
							mdi-file
						</v-icon>
					</template>

				</v-data-table>
			</v-card-text>
		</v-card>
		<dialog-calendar :dialog.sync="dialog" :mode="mode" :calendarEdit="calendar" />
	</Layout>
</template>

<script>
import axios from 'axios';
import store from '@/store'
import DialogCalendar from '@/components/calendar/DialogCalendar.vue'
import Helpers from '@/services/Helpers.js'
const header = { "Token": store.state.userModule.token }
const configuration = { headers: header }
export default {
	name: 'CalendarList',
	components: {
		DialogCalendar
	},
	data: () => ({
		dialog: false,
		calendars: [],
		calendar: {},
		mode: 'New',
		search: '',
		loading: false,
		headers: [
			{ text: 'TITULO', align: 'start', value: 'title' },
			{ text: 'FECHA INICIO', align: 'start', value: 'date' },
			{ text: 'FECHA FIN', align: 'start', value: 'dateEnd' },
			{ text: '', value: 'action', sortable: false, },
		],
	}),
	mounted() {
		this.getData()
	},
	methods: {
		async getData() {
			let me = this;
			await axios.get('calendar/list', configuration)
				.then(
					function (response) {
						me.calendars = response.data;
						me.loading = false
					})
				.catch(function (e) {
					me.loading = false
					console.log(e.message)
				})
		},
		editCalendar(item) {
			this.dialog = true
			this.calendar = item
			this.calendar.date = Helpers.dateFormatUs(item.date)
			this.calendar.dateEnd = Helpers.dateFormatUs(item.dateEnd)
			this.mode = 'Edit'
		},
		openDialog(mode) {
			this.dialog = true
			this.calendar = {}
			this.mode = 'New'
		},
		dateFormat(date) {
			return Helpers.dateFormatEs(date)
		},
	},
	computed: {
	}
}
</script>

<style lang="scss" scoped>
.botones_list {
	margin: 0px 10px 10px 0px;
	font-size: xx-large;
}
</style>