var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('v-card',{staticClass:"d-sm-flex align-sm-center justify-sm-space-between mb-6 elevation-0"},[_c('v-card',{staticClass:"pa-2 text-left elevation-0 order-0 d-flex align-center"},[_c('h2',[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-calendar-clock")]),_vm._v(" CALENDARIO")],1),_c('v-btn',{staticClass:"ml-3",attrs:{"fab":"","dark":"","color":"primary"},on:{"click":_vm.openDialog}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-card',{staticClass:"pa-2 order-2",attrs:{"flat":"","width":"400"}},[_c('v-text-field',{staticClass:"text-right",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-card',[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.calendars,"search":_vm.search,"item-key":"_id","loading":_vm.loading,"loading-text":"Cargando... Espere","no-data-text":"Sin registros para mostrar","footer-props":{
					'items-per-page-options': [10, 15, 20, 50]
				},"items-per-page":20},scopedSlots:_vm._u([{key:"item.evento",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" ")]}},{key:"item.date",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.date))+" ")]}},{key:"item.dateEnd",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.dateEnd))+" ")]}},{key:"item.action",fn:function(ref){
				var item = ref.item;
return [_c('v-icon',{staticClass:"botones_list",attrs:{"color":"primary"},on:{"click":function($event){return _vm.editCalendar(item)}}},[_vm._v(" mdi-file ")])]}}])})],1)],1),_c('dialog-calendar',{attrs:{"dialog":_vm.dialog,"mode":_vm.mode,"calendarEdit":_vm.calendar},on:{"update:dialog":function($event){_vm.dialog=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }